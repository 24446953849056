import React, { Component } from 'react';
import "./App.css"
import './Sponsor.css'
import Menu from "./section/Menu"
import Logo from "./section/Logo";
import logo_nare from "./asset/logo/logo-nare-sponsor.jpg";
import logo_nare_cup from "./asset/logo/logo-nare-cup.jpg";
import logo_aj from "./asset/logo/logo-aj.png";
import logo_raon from "./asset/logo/logo-raon.png";
import logo_autosur from "./asset/logo/logo-autosur.jpg";
import logo_tava from "./asset/logo/logo-tava-big.jpg"
import logo_kp from "./asset/logo/logo-kp.png"
import logo_christophe from "./asset/logo/logo-christophe.jpg"
import logo_touche_finale from "./asset/logo/logo-touche-finale.jpg"
import logo_bds from "./asset/logo/logo-bds.jpg"
import logo_bdl from "./asset/logo/logo-bdl.jpg"
import logo_tava_mob from "./asset/logo/logo-tava-mob.jpg"
import logo_martin from "./asset/logo/logo-martin.jpg"
import logo_schoenher from "./asset/logo/logo-schoenher.jpg"
import logo_american_taxi from "./asset/logo/logo-american-taxi.jpg"
import logo_step from "./asset/logo/logo-step.jpg"

class Sponsor extends Component {
    render() {
        return(
            <div className="nare">
                <div className="header">
                    <Menu />
                </div>
                <div className="content">
                    <h2 className={"sponsor-title"}>Nos sponsors</h2>
                    <h3 className={"sponsor-title"}>Cliquez sur leur logos pour visiter leur site internet</h3>
                    <div className="sponsor">

                        <a target="_blank" className="logo-link" href="https://nare.club/home"><Logo src={logo_nare}
                                                                                                     className="partner-logo nare"
                                                                                                     alt="Nare"/></a>
                        <a target="_blank" className="logo-link" href="https://cup.nare.club"><Logo src={logo_nare_cup}
                                                                                                    className="partner-logo nare cup"
                                                                                                    alt="Nare cup"/></a>
                        <a target="_blank" className="logo-link" href="https://auto-raon.club"><Logo src={logo_tava}
                                                                                                     className="partner-logo tava"
                                                                                                     alt="TAVA"/></a>

                        <a target="_blank" className="logo-link" href="https://mob.auto-raon.club/"><Logo
                            src={logo_tava_mob} className="partner-logo tava-mob" alt="TAVA Mob'Moto"/></a>

                        <a target="_blank" className="logo-link" href="https://www.pagesjaunes.fr/pros/03118627"><Logo
                            src={logo_martin} className="partner-logo martin" alt="Boulangerie Martin"/></a>

                        <a target="_blank" className="logo-link" href="https://www.kpouvertures.com/"><Logo
                            src={logo_kp} className="partner-logo kp" alt="KP Ouvertures"/></a>

                        <a target="_blank" className="logo-link" href="https://www.americans-taxi-lpa.fr/"><Logo
                            src={logo_american_taxi} className="partner-logo american-taxi" alt="Americans Taxi"/></a>

                        <a target="_blank" className="logo-link"
                           href="https://controle-technique.autosur.fr/596-autosur-saint-die-des-vosges"><Logo
                            src={logo_autosur} className="partner-logo autosur" alt="Autosur"/></a>

                        <a target="_blank" className="logo-link"
                           href="https://www.facebook.com/people/La-Touche-Finale-II/100070900715150"><Logo
                            src={logo_touche_finale} className="partner-logo touche-finale" alt="La touche finale"/></a>

                        <a target="_blank" className="logo-link"
                           href="https://www.scf-habitat.fr/"><Logo
                            src={logo_schoenher} className="partner-logo schoenher" alt="Schoenher"/></a>

                        <a target="_blank" className="logo-link" href="https://aj-enseignes.com/"><Logo
                            src={logo_aj} className="partner-logo aj-enseignes" alt="AJ Enseignes"/></a>

                        <a target="_blank" className="logo-link" href="http://www.garagechristophe.fr/"><Logo
                            src={logo_christophe} className="partner-logo christophe" alt="Garage Christophe"/></a>

                        <a target="_blank" className="logo-link" href="http://www.mairie-bandelaveline.fr/"><Logo
                            src={logo_bdl} className="partner-logo ban-de-laveline" alt="Ban de laveline"/></a>

                        <a target="_blank" className="logo-link" href="http://www.raonletape.fr"><Logo src={logo_raon}
                                                                                                       className="partner-logo raon"
                                                                                                       alt="Raon L'étape"/></a>

                        <a target="_blank" className="logo-link" href="https://fr.wikipedia.org/wiki/Ban-de-Sapt"><Logo
                            src={logo_bds} className="partner-logo ban-de-sapt" alt="Ban de sapt"/></a>

                        <a target="_blank" className="logo-link" href="https://solutions-step.com"><Logo src={logo_step} className="partner-logo step" alt="Step" /></a>

                    </div>
                </div>
            </div>
        )
    }
}

export default Sponsor